const getisLoggedIn = () => {
  // Verifică în toate spațiile de stocare pentru a vedea dacă utilizatorul este logat
  let isLoggedIn = sessionStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedIn') || getCookie('_isLoggedInCjpc');
  return isLoggedIn === 'true';
};

const getCookie = (name) => {
  // Funcția getCookie
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};
const deleteCookie = (name) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

let alreadyChecked = false;

const verificareToken = () => {
  // Verifică dacă utilizatorul este logat
  if (!getisLoggedIn()) {
    return { token: null, ok2goval: null };
  }

  // Verifică token-ul în toate spațiile de stocare
  let token = sessionStorage.getItem('token') || localStorage.getItem('token') || getCookie('_tokenCjpc');
  let ok2goval = sessionStorage.getItem('ok2go') || localStorage.getItem('ok2go') || getCookie('ok2go');

  if (!token) {
    return { token: null, ok2goval: null };
  }

  if (ok2goval === 'false') {
    if (window.location.pathname === '/logout') {
      // Dacă utilizatorul este pe /logout și ok2go este false, ștergem tot ce este legat de ok2go
      localStorage.removeItem('ok2go');
      sessionStorage.removeItem('ok2go');
      deleteCookie('ok2go');  
    } else if (window.location.pathname !== '/licentiere') {
      // Dacă nu suntem pe /licentiere și ok2go este false, redirecționăm la /licentiere
      window.location.href = '/licentiere';
      return { token, ok2goval };  
    }
  }
  
   // ✅ Fă un singur fetch dacă tokenul este prezent și validabil
   if (token && ok2goval === 'true' && !alreadyChecked) {
    alreadyChecked = true;

    fetch(`${window.API_URL}user`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
      }
    })
      .then((res) => res.json().then((body) => ({ status: res.status, body })))
      .then(({ status, body }) => {
        alreadyChecked = false;
        if (status === 401 || body?.message === 'Utilizatorul nu este logat!') {
          console.warn('🔒 Token invalid. Se face redirect...');
          sessionStorage.removeItem('token');
          localStorage.removeItem('token');
          deleteCookie('_tokenCjpc');
          window.location.href = '/logout';
        }
      })
      .catch((err) => {
        console.error('💥 Eroare la validarea tokenului:', err);
        window.location.href = '/logout';
      });
  }

  return { token, ok2goval };
};

export default verificareToken;
