import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Button, Box, Typography, Alert, Select, MenuItem, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

function InfoObiecteDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { id } = useParams();
    const apiUrl = window.API_URL; 
      const { token } = verificareToken();
    const [obiecteDosar, setObiecteDosar] = useState([]);
    const [obiecte, setObiecte] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentObiectDosar, setCurrentObiectDosar] = useState(null);

    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [currentAdminObiect, setCurrentAdminObiect] = useState(null);
    const [textareaValue, setTextareaValue] = useState("");
    const [filtru, setFiltru] = React.useState("");


  const [formState, setFormState] = useState({
    idobiect: '',
  });
  const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);

  const [adminOpen, setAdminOpen] = useState(false);

  const currentDate = new Date().toISOString().split('T')[0];

  const fetchData = async () => {
    setLoading(true);
    try {
      const obiecteDosarResponse = await fetch(`${apiUrl}infoObiecteDosar/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const obiecteDosarData = await obiecteDosarResponse.json();

      const obiecteResponse = await fetch(`${apiUrl}obiecte/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const obiecteData = await obiecteResponse.json();

      setObiecteDosar(obiecteDosarData.data.obiecteDosar);
      setObiecte(obiecteData.data.obiecte);
    } catch (error) {
      console.error('A apărut o eroare!', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, apiUrl]);

  const handleOpen = (obiectDosar) => {
    if (obiectDosar) {
      setFormState((prevState) => ({
        ...prevState,
        idobiect: obiectDosar.idobiect || '',
      }));
    } else {
      setFormState({
        idobiect: '',
      });
    }
    setCurrentObiectDosar(obiectDosar);
    setOpen(true);
  };
  

  const handleClose = () => {
    setCurrentObiectDosar(null);
    setFormState({
      idobiect: '',
    });
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdaugaSauEditeaza = async () => {
    setIsAddingOrEditing(true);

    const queryParams = new URLSearchParams({
      idobiect: formState.idobiect,
    });

    const handleAdminModalOpen = (obiect) => {
        if (obiect) {
          setCurrentAdminObiect(obiect);
          setTextareaValue(obiect.denumire || "");
        } else {
          setCurrentAdminObiect(null);
          setTextareaValue("");
        }
        setAdminModalOpen(true);
      };
      
      const handleAdminModalClose = () => {
        setCurrentAdminObiect(null);
        setTextareaValue("");
        setAdminModalOpen(false);
      };
      
      const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
      };

    try {
      if (currentObiectDosar) {
        const response = await fetch(`${apiUrl}obiecteDosar/${currentObiectDosar.id}?iddosar=${id}&${queryParams}`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setObiecteDosar((prevObiecteDosar) => {
          return prevObiecteDosar.map((obiectDosarItem) => {
            if (obiectDosarItem.id === currentObiectDosar.id) {
              return data.data.obiectDosar;
            }
            return obiectDosarItem;
          });
        });
      } else {
        const response = await fetch(`${apiUrl}obiecteDosar?iddosar=${id}&${queryParams}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setObiecteDosar((prevObiecteDosar) => [...prevObiecteDosar, data.data.obiectDosar]);
      }
      handleClose();
      fetchData();
    } catch (error) {
      console.error('A apărut o eroare!', error);
    } finally {
      setIsAddingOrEditing(false);
      setCurrentObiectDosar(null);
      fetchData();
    }
  };

  const handleSterge = async (obiectDosarId) => {
    setIsAddingOrEditing(true);

    Swal({
        title: 'Sigur doriți ștergerea?',
        text: 'Această acțiune nu poate fi anulată!',
              icon: 'warning',
              buttons: true,
              dangerMode: true,
          }).then(async (willDelete) => {
              if (willDelete) {
                  try {
                    await fetch(`${apiUrl}obiecteDosar/${obiectDosarId}`, {
                        method: 'DELETE',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      });
                      setObiecteDosar(obiecteDosar.filter((item) => item.id !== obiectDosarId));
                      Swal('Șters!', 'Obiectul dosarului a fost șters.', 'success');
                      fetchData();
                  } catch (error) {
                      console.error('A apărut o eroare la ștergere!', error);
                  } finally {
                    setIsAddingOrEditing(false);
                  }
              } else {
                setIsAddingOrEditing(false);
              }
          });   
    };

    const handleStergeObiectAdmin = async (obiectDosarId) => {
        setIsAddingOrEditing(true);
    
        Swal({
            title: 'Sigur doriți ștergerea?',
            text: 'Această acțiune nu poate fi anulată!',
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true,
              }).then(async (willDelete) => {
                  if (willDelete) {
                      try {
                        await fetch(`${apiUrl}obiecte/${obiectDosarId}`, {
                            method: 'DELETE',
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          });
                          setObiecteDosar(obiecteDosar.filter((item) => item.id !== obiectDosarId));
                          Swal('Șters!', 'Obiectul a fost șters.', 'success');
                          fetchData();
                      } catch (error) {
                          console.error('A apărut o eroare la ștergere!', error);
                      } finally {
                        setIsAddingOrEditing(false);
                      }
                  } else {
                    setIsAddingOrEditing(false);
                  }
              });   
        };

  const handleAdminOpen = () => {
    setAdminOpen(true);
  };

  const handleAdminClose = () => {
    setAdminOpen(false);
  };



  const handleSaveAdminObiect = async () => {
    const apiUrl = currentAdminObiect ? `${window.API_URL}obiecte/${currentAdminObiect.id}` : `${window.API_URL}obiecte`;
    const requestOptions = {
      method: currentAdminObiect ? 'PATCH' : 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    };
  
    try {
        const response = await fetch(`${apiUrl}?denumire=${textareaValue}&iddosar=${id}`, requestOptions);
        if (response.ok) {
        const data = await response.json();
        fetchData(); 
      } else {
        console.error('A apărut o eroare la salvarea datelor');
      }
    } catch (error) {
      console.error('A apărut o eroare la salvarea datelor', error);
    } finally {
      handleAdminModalClose();
    }
  };

  
  const handleAdminModalOpen = (obiect) => {
    if (obiect) {
      setCurrentAdminObiect(obiect);
      setTextareaValue(obiect.denumire || "");
    } else {
      setCurrentAdminObiect(null);
      setTextareaValue("");
    }
    setAdminModalOpen(true);
  };
  
  const handleAdminModalClose = () => {
    setCurrentAdminObiect(null);
    setTextareaValue("");
    setAdminModalOpen(false);
  };
  
  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  
  return (
    <div>
      <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }}>Obiecte dosar</Typography>
        <div>
          {areDrept(DreptDeModificareDosare) ? ( 
            <>
              <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                onClick={() => handleOpen(null)}
                variant="contained"
                size="small"
                color="secondary"
                className='mr-2' 
                startIcon={<AddIcon />}
                style={{marginRight:'20px'}}
                >
                Adăugare obiect nou
                </Button>
                <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                onClick={handleAdminOpen}
                variant="contained"
                size="small"
                color="primary"
                className='ml-2' 
                >
                Administrare obiecte
                </Button>
            </>
          ) : null } 
        </div>
       </div>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>
      ) : (
        <List>
          {obiecteDosar.length === 0 ? (
            <Alert severity="info">Nu există obiecte atașate dosarului.</Alert>
          ) : (
            obiecteDosar.map((obiectDosarItem, index) => (
              <ListItem
                key={obiectDosarItem.id}
                className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
              >
                <ListItemText
                  primary={`${obiectDosarItem.denumire || "Denumire indisponibilă"}`}
                  secondary={
                    <Typography variant="body2">
                       
                    </Typography>
                  }
                />

                {areDrept(DreptDeModificareDosare) ? (
                  <>
                    <IconButton aria-label="editeaza" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleOpen(obiectDosarItem)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton  disabled={!areDrept(DreptDeModificareDosare)}aria-label="sterge" onClick={() => handleSterge(obiectDosarItem.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : null } 
              </ListItem>
            ))
          )}
        </List>
      )}
      {/* Dialog de adăugare și editare */}
      <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle className='titluModal' id="form-dialog-title">
                {currentObiectDosar ? 'Editare obiect dosar' : 'Adăugare obiect dosar'}
            </DialogTitle>
            
            <DialogContent className='mt-3' style={{ minWidth: '300px', maxWidth: '700px' }}>
                <div className="input-section">
                    <Typography variant="h6" className="input-section-title">
                        Selectați un obiect
                    </Typography>
                    
                    <TextField
                        className='mb-2'
                        style={{ background: '#FFFFFF', width: '100%' }}
                        fullWidth
                        type="text"
                        value={filtru}
                        onChange={(e) => setFiltru(e.target.value)}
                        placeholder="Căutați un obiect..."
                    />

                    <List>
                        {obiecte
                            .filter((obiect) =>
                                obiect && obiect.denumire && obiect.denumire.toLowerCase().includes(filtru.toLowerCase())
                            )
                            .map((obiect, index) => (
                                <ListItem
                                    button
                                    key={obiect.id}
                                    className={`list-item ${index % 2 === 0 ? 'striped' : ''}`}
                                    selected={formState.idobiect === obiect.id} // Evidențiere selecție
                                    onClick={() => {
                                        setFormState(prevState => ({
                                            ...prevState,
                                            idobiect: obiect.id
                                        }));
                                    }}
                                >
                                    <ListItemText primary={obiect.denumire} />
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            </DialogContent>
            
            <DialogActions>
                <Button
                    onClick={handleAdaugaSauEditeaza}
                    color="success"
                    disabled={!formState.idobiect || isAddingOrEditing}
                >
                    {isAddingOrEditing ? 'Se procesează...' : (currentObiectDosar ? 'Salvare' : 'Adăugare')}
                </Button>
                <Button onClick={handleClose} color="error">
                    Anulare
                </Button>
            </DialogActions>
        </Dialog>

      {/* administrare obiecte */}
      <Dialog maxWidth="md" open={adminOpen} onClose={handleAdminClose} aria-labelledby="admin-dialog-title">
        <DialogTitle className='titluModal' id="admin-dialog-title">
          Administrare obiecte
          <Button
              onClick={() => handleAdminModalOpen(null)}
              variant="contained"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className="pull-right"
            >
              Obiect nou
            </Button>
            <TextField
                className='mt-3 mb-1' 
                style={{background:'#FFFFFF'}}
                fullWidth
                type="text" 
                value={filtru} 
                onChange={(e) => setFiltru(e.target.value)} 
                placeholder="Filtrează obiectele..." 
            />
        </DialogTitle>
        <DialogContent> 
            
        <List>
            {obiecte
              .filter((obiect) => obiect && obiect.denumire && obiect.denumire.toLowerCase().includes(filtru.toLowerCase()))
              .map((obiect, index) => (
                <ListItem
                    key={obiect.id}
                    className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
                >
                    <ListItemText
                    primary={obiect.denumire}
                    />
                    <IconButton
                    onClick={() => handleAdminModalOpen(obiect)}
                    variant="contained"
                    size="small"
                    color="default"
                    >
                    <EditIcon />
                    </IconButton>
                    <IconButton
                    onClick={() => handleStergeObiectAdmin(obiect.id)}
                    variant="contained"
                    color="default"
                    size='small'
                    >
                    <DeleteIcon />
                    </IconButton>
                </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdminClose} color="error">
            Renunțare
          </Button>
        </DialogActions>
      </Dialog>
        {/*modal administrare obiecte editare / adaugare*/}
        <Dialog open={adminModalOpen} onClose={handleAdminModalClose}>
        <DialogTitle>{currentAdminObiect ? "Editare Obiect" : "Adăugare Obiect Nou"}</DialogTitle>
        <DialogContent>
            <textarea
            minRows={3}
            style={{ width: '100%' }}
            value={textareaValue}
            onChange={handleTextareaChange}
            ></textarea>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleSaveAdminObiect()}  disabled={!textareaValue.trim()}
 color="success">
            Salvare
            </Button>
            <Button onClick={handleAdminModalClose} color="error">
            Anulare
            </Button>
        </DialogActions>
        </Dialog>

    </div>
  );
}

export default InfoObiecteDosar;
