import { useState, useEffect } from 'react';
import useUserPrefService from './preferinteUtilizatorServiciu.js';

function usePreferinteUtilizator() {
  const userPrefsData = useUserPrefService();
  const [preferinte, setPreferinte] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (userPrefsData && userPrefsData.length > 0) {
        const userData = userPrefsData[0];

      setPreferinte({
        colorDCL: userData.colorDCL || "",
        colorDCF: userData.colorDCF || "",
        colorDIL: userData.colorDIL || "",
        colorDIF: userData.colorDIF || "",
        colorCT: userData.colorCT || "",
        colorCA: userData.colorCA || "",
        colorCU: userData.colorCU || "",
        colorDA: userData.colorDA || "",
        culoareAplicatie: userData.culoareAplicatie || "",
        openinnewwindow: !!userData.openinnewwindow,
        openlastopened: !!userData.openlastopened,
        intervalpopup: userData.intervalpopup || "",
        notificari_termene: !!userData.notificari_termene,
        uianimations: !!userData.uianimations,
        allowsounds: !!userData.allowsounds,
        afisare_notificari: !!userData.afisare_notificari,
        afisare_notificari_azi: !!userData.afisare_notificari_azi,
        showpreview: !!userData.showpreview,
        calendarlabels: userData.calendarlabels || "",
        dosarepepagina: userData.dosarepepagina || "",
        alertapublicare: userData.alertapublicare || 0,
        reset_password: userData.reset_password || 0,
        send_notificari_push: userData.send_notificari_push || "",
        send_notificari_email: userData.send_notificari_email || "",
        send_notificari_sms: userData.send_notificari_sms || "",
      });
      setLoaded(true);
    }
  }, [userPrefsData]);

  //console.log("openlastopened:", preferinte.openlastopened);

  return { preferinte, loaded };
}

export default usePreferinteUtilizator;
